/**
 * PNP CMS vite bootstrapper
 * CraigE 29-Aug-2023
 */

import '../../resources/js/notice.js';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import $ from 'jquery';
window.$ = window.jQuery = $;

import _ from 'lodash';
window._ = _;

import DataTable from "datatables.net-bs5";
window.DataTable = DataTable;

import select2 from 'select2';
select2();

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Sortable from 'sortablejs';
window.Sortable = Sortable;

import Swal from 'sweetalert2';
window.Swal = Swal;

import '../../resources/js/toast.js';

import {CONSTANTS} from './constants.js';
window.CONSTANTS = CONSTANTS;

const event = new Event('documentReady');
window.dispatchEvent(event);
